import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import Loader from '@common/src/common/loader/loader'
import { setSelectedStore } from '../../../redux/modules/auth/action'
import { USER_TYPES } from '@common/src/constants/constants'

const StoreDrop = () => {
  const dispatch = useDispatch()
  const { stores = [], loading = true, user, selectedStore } = useSelector((state) => state.auth || {})
  const onChange = (obj) => {
    dispatch(setSelectedStore(obj))
  }
  return (
    <>
      {loading && <Loader />}
      {!loading && user?.userType === USER_TYPES.PULSE_SUB_ADMIN && (
        <Select
          className="ti-form-select rounded-sm !p-0"
          placeholder="Choose Store"
          options={stores}
          defaultValue={selectedStore || stores?.[0]}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default StoreDrop
