//login.jsx
import { Fragment, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { ThemeChanger } from '../redux/modules/theme/action'
import desktopLogo from '../assets/images/brand-logos/desktop-logo.png'
import { LocalStorageBackup } from '../components/common/switcher/switcherdata/switcherdata'
import { auth } from './firebaseapi'

const Login = ({ ThemeChanger }) => {
  const { loading } = useSelector((state) => state.auth)
  const [passwordshow1, setPasswordshow1] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState({})
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
    setError('')
  }
  const navigate = useNavigate()
  const routeChange = () => {
    const path = `${import.meta.env.BASE_URL}dashboard`
    navigate(path)
  }

  const login = (e) => {
    setIsLoggingIn(true)
    e.preventDefault()
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((user) => {
        setIsLoggingIn(false)
        console.log(user)
        routeChange()
      })
      .catch((err) => {
        console.log(err)
        setIsLoggingIn(false)
        setError(err.message)
      })
  }

  useEffect(() => {
    LocalStorageBackup(ThemeChanger)
  }, [])

  return (
    <Fragment>
      <div className="container">
        <div className="flex justify-center authentication authentication-basic items-center h-full text-defaultsize text-defaulttextcolor">
          <div className="grid grid-cols-12">
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-8 col-span-12">
              <div className="my-[1.5rem] flex justify-center">
                <Link to={`${import.meta.env.BASE_URL}dashboard`}>
                  <img src={desktopLogo} alt="logo" className="desktop-logo" />
                  <img src={desktopLogo} alt="logo" className="desktop-dark" />
                </Link>
              </div>

              <div className="box !p-[3rem]">
                <div
                  className="box-body"
                  role="tabpanel"
                  id="pills-with-brand-color-01"
                  aria-labelledby="pills-with-brand-color-item-1"
                >
                  <p className="h5 font-semibold mb-2 text-center">Sign In</p>
                  <p className="mb-4 text-[#8c9097] dark:text-white/50 opacity-[0.7] font-normal text-center"></p>
                  {error && (
                    <div className="alert-danger px-4 py-3 shadow-md mb-2" role="alert">
                      <div className="flex">
                        <div className="py-1"></div>
                        <div>{error}</div>
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-12 gap-y-4">
                    <div className="xl:col-span-12 col-span-12">
                      <label htmlFor="signin-username" className="form-label text-default">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg w-full !rounded-md"
                        onChange={changeHandler}
                        value={data.email}
                        id="signin-username"
                        placeholder="Email"
                      />
                    </div>
                    <div className="xl:col-span-12 col-span-12 mb-2">
                      <label htmlFor="signin-password" className="form-label text-default block">
                        Password
                        <Link
                          to={`${import.meta.env.BASE_URL}authentication/resetpassword/resetbasic`}
                          className="float-end text-danger"
                        >
                          Forgot password ?
                        </Link>
                      </label>
                      <div className="input-group">
                        <input
                          type={passwordshow1 ? 'text' : 'password'}
                          className="form-control form-control-lg !rounded-s-md"
                          name="password"
                          placeholder="Password"
                          value={data.password}
                          onChange={changeHandler}
                        />
                        <button
                          onClick={() => setPasswordshow1(!passwordshow1)}
                          aria-label="button"
                          className="ti-btn ti-btn-light !rounded-s-none !mb-0"
                          type="button"
                          id="button-addon2"
                        >
                          <i className={`${passwordshow1 ? 'ri-eye-line' : 'ri-eye-off-line'} align-middle`}></i>
                        </button>
                      </div>
                    </div>
                    <div className="xl:col-span-12 col-span-12 grid mt-2">
                      <button
                        onClick={login}
                        disabled={loading}
                        className="ti-btn ti-btn-primary !bg-primary !text-white !font-medium"
                      >
                        {loading || isLoggingIn ? <span className="me-2">Loading </span> : `Sign In`}
                        {(loading || isLoggingIn) && (
                          <span className="loading">
                            <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  localVariable: state.theme,
})

export default connect(mapStateToProps, { ThemeChanger })(Login)
