import * as Yup from 'yup'
import PropTypes from 'prop-types'

import InputBox from '@common/src/common/inputbox/inputbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import { ROLE, STATUS } from '@common/src/constants/constants'
import { useToast } from '@common/src/common/Toast/ToastProvider'

import { createRole, editRole } from '../service/firebase/roles'
import Tree from '../checkboxTree/Tree'

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().max(30, 'Role name should not exceed 30 characters').required('Role name is required.'),
  description: Yup.string().max(100, 'Description should not exceed 100 characters'),
  roles: Yup.array().min(1, 'Please select at least one permission for this role.'),
})

const AddRole = ({ id, permissions, data, isEdit, onComplete }) => {
  const toast = useToast()
  const onSubmit = async (values, actions) => {
    try {
      const role = {
        name: values.name,
        description: values.description,
        permissions: values.roles,
        updatedAt: new Date(),
      }
      if (isEdit) {
        const { success, message } = await editRole(data.id, role)
        success ? toast.success(message) : toast.error(message)
      } else {
        role.status = STATUS.ACTIVE
        role.createdAt = new Date()
        role.type = import.meta.env.VITE_ROLE_TYPE
        const { success, message } = await createRole(role)
        success ? toast.success(message) : toast.error(message)
      }
      actions?.resetForm()
      onComplete?.()
    } catch (error) {
      toast.error('Error while saving role')
      console.error('Error saving role: ', error)
      
    } finally {
      actions?.setSubmitting(false)
    }
  }
  return (
    <OffCanvasForm
      title={isEdit ? 'Edit Role' : 'Create Role'}
      initialValues={{
        name: isEdit ? data.name : '',
        description: isEdit ? data.description : '',
        roles: isEdit ? data.permissions : [],
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      okText={isEdit ? 'Update' : 'Save'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
    >
      {({ setFieldValue, values, handleChange, handleBlur, errors, touched, submitForm, isSubmitting }) => {
        return (
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <InputBox
                id="name"
                label="Name"
                placeholder="Name"
                value={values.name}
                maxLength={30}
                onChange={handleChange}
                onBlur={handleBlur}
                tabIndex={1}
                required={true}
                errorText={touched.name && errors.name ? errors.name : ''}
              />
            </div>
            <div className="col-span-12">
              <InputBox
                id="description"
                label="Description"
                placeholder="Description"
                value={values.description}
                maxLength={100}
                onChange={handleChange}
                onBlur={handleBlur}
                tabIndex={2}
                errorText={touched.description && errors.description ? errors.description : ''}
              />
            </div>

            <div className="col-span-12">
              <div className="mt-2">
                <label htmlFor="roles" className="form-label">
                  Permissions <span style={{ color: 'red' }}>*</span>
                </label>
                <Tree
                  tabIndex={3}
                  data={permissions}
                  onChange={(value) => {
                    setFieldValue('roles', value)
                  }}
                  values={values.roles}
                />
                {touched.roles && errors.roles && (
                  <label htmlFor={id} className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0" style={{ color: 'red' }}>
                    {errors.roles}
                  </label>
                )}
              </div>
            </div>
          </div>
        )
      }}
    </OffCanvasForm>
  )
}
AddRole.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  isEdit: PropTypes.bool.isRequired, // should be 'bool' instead of 'boolean'
  onComplete: PropTypes.func.isRequired,
  id: PropTypes.string,
}
export default AddRole
