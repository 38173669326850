import React from 'react';
import PropTypes from 'prop-types';

const PreviewImage = ({ src, isRemovable = false, onRemove}) => {

    return (
        <div className="relative inline-block flex justify-center bg-gray-100 max-h-[300px]">
            <img src={src} alt="" className="block w-full h-full object-contain" />
            {isRemovable &&
                <button
                    className="absolute top-1 right-1 text-white bg-danger hover:bg-red/80  py-0.5 px-2"
                    onClick={onRemove}
                >
                    &times;
                </button>
            }
        </div>
    );
};
PreviewImage.propTypes = {
    src: PropTypes.string.isRequired,
    isRemovable: PropTypes.bool,
    onRemove: PropTypes.func,
}
export default PreviewImage;
