import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './pages/App.jsx'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

import ScrollToTop from '@common/src/common/scrolltotop/scrolltotop.jsx'
import { ToastProvider } from '@common/src/common/Toast/ToastProvider'
import Users from '@common/src/users/users'
import Role from '@common/src/roles/roles.jsx'
import Crm from './container/dashboards/crm/crm.jsx'
import './index.scss'
import ErrorBoundary from './pages/ErrorBoundary.jsx'
import Login from './firebase/login.jsx'
import Join from './firebase/join.jsx'
import Auth from './firebase/auth.jsx'
import PrivateRoute from './firebase/privateroute.jsx'
import { AuthProvider } from './firebase/authcontext.jsx'
import store from './redux/store/index.js'
import Error403 from './container/error/403error.jsx'
import Error404 from './container/error/404error.jsx'
import { MENUITEMS } from './shared/components/sidebar/sidemenu/sidemenu.jsx'
import Empty from './container/empty/empty.jsx'
import { ApolloProvider } from '@apollo/client'
import client from '@common/src/lib/graphql/apolloClient.js'
import Stores from './container/stores/storeList.jsx'
const componentsMap = {
  'dashboard': Crm,
  users: Users,
  roles: Role,
  'store': Stores,
}

const renderRoutes = (items) => {
  return items.flatMap((item) => {
    // If the item is a link
    if (item?.type === 'link') {
      const Component = componentsMap[item.path.replace(`${import.meta.env.BASE_URL}`, '')] || Empty
      return (
        <Route
          path={item.path}
          element={
            <PrivateRoute allowedRoles={[item.key]}>
              <Component />
            </PrivateRoute>
          }
        />
      )
    }

    // If the item is a sub menu with children
    if (item?.type === 'sub' && item?.children) {
      return renderRoutes(item.children) // Recursively render children
    }

    // If the item is not a route, log and skip it
    return null
  })
}
// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   environment: import.meta.env.MODE,
//   tracesSampleRate: import.meta.env.MODE === 'production' ? 0.1 : 1.0,
//   debug: import.meta.env.MODE === 'production' ? 0.1 : 1.0,
//   replaysSessionSampleRate: import.meta.env.MODE === 'production' ? 0.1 : 1.0,
//   replaysOnErrorSampleRate: 1.0,
// })

ReactDOM.createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <BrowserRouter>
      <React.Suspense>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <AuthProvider>
            <ToastProvider>
              <ScrollToTop />
              <Routes>
                <Route path={`${import.meta.env.BASE_URL}`} element={<Auth />}>
                  <Route index element={<Login />} />
                  <Route path="/join" element={<Join />} />
                </Route>
                <Route path={`${import.meta.env.BASE_URL}`} element={<App />}>
                  <Route path={`${import.meta.env.BASE_URL}dashboard`} element={<Crm />} />
                  {renderRoutes(MENUITEMS)}
                </Route>
                <Route path="/unauthorized" element={<Error403 />} />
                <Route path="*" element={<Error404 />} />
              </Routes>
            </ToastProvider>
          </AuthProvider>
        </Provider>
      </ApolloProvider>
      </React.Suspense>
    </BrowserRouter>
  </ErrorBoundary>
)
