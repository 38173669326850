import { useState, useEffect, useMemo, useRef } from 'react'
import { collection, query, where } from 'firebase/firestore'

import PageHeader from '@common/src/pageheader/pageheader'
import TableList from '@common/src/common/Table/TableList'
import { ROLE, COLLECTIONS, STATUS } from '@common/src/constants/constants'

import Permission from './permission.jsx'
import { getMasterPermission } from '../service/firebase/masterpermissions'
import { db } from '../../../src/firebase/firebaseapi'
import AddRole from './addrole'
import FloatingCanvas from '../common/offcanvas/floatingCanvas.jsx'
import useFloatingCanvas from '../hooks/useFloatingCanvas.js'

const getColums = (permissions, onEditClick) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'text-start',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      className: 'text-start',
    },
    {
      title: 'Role',
      key: 'permissions',
      className: 'text-start',
      render: (_, row) => <Permission permissionArray={row.permissions} permissions={permissions} />,
    },
    {
      title: 'Action',
      key: 'action',
      className: 'text-start',
      render: (text, record) => (
        <div className="space-x-1 rtl:space-x-reverse">
          <button
            onClick={() => onEditClick(record)}
            aria-label="button"
            type="button"
            className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
          >
            <i className="ri-pencil-line"></i>
          </button>
        </div>
      ),
    },
  ]
}
let ref = collection(db, COLLECTIONS.ROLES)
ref = query(ref, where('status', '==', STATUS.ACTIVE), where('type', '==', import.meta.env.VITE_ROLE_TYPE))
const pagination = {
  pageSize: 20,
}
const Roles = () => {
  const [permissions, setPermissions] = useState([])
  const tableRef = useRef(null)
  const [data, setData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()

  const onEditClick = (data) => {
    setData(data)
    setIsEdit(true)
    openOffCanvas('addRole', 'Edit Role')
  }
  const columns = useMemo(() => getColums(permissions, onEditClick), [permissions])

  const convertToTree =function (structure) {
    function traverse(node) {
        const treeNode = {
            value: node.id,
            label: node.name || node.id,
        };

        if (node.sub) {
            treeNode.children = Object.values(node.sub).map(traverse);
        } else if (node.actions) {
            treeNode.children = Object.entries(node.actions).map(([key, action]) => ({
                value: key,
                label: action.name,
            }));
        }

        return treeNode;
    }

    return structure.map(traverse);
}
  const fetchPermissions = async () => {
    try {
      const list = await getMasterPermission()
      const _data = convertToTree(list);
      setPermissions(_data)
    } catch (error) {
      console.error('Error fetching permissions: ', error)
    }
  }
  useEffect(() => {
    fetchPermissions()
  }, [])

  const onComplete = () => {
    if (tableRef.current) {
      tableRef.current.refreshCurrentPage()
      closeOffCanvas()
    }
  }
  const onClose = () => {
    closeOffCanvas(false)
  }
  const onAddClick = () => {
    setData(null)
    setIsEdit(false)
    openOffCanvas('addRole', 'Add Role')
  }
  return (
    <>
      <PageHeader currentpage="Role" activepage="User Management" mainpage="roles" />
      <TableList
        name="Role List"
        columns={columns}
        dbRef={ref}
        pagination={pagination}
        tools={{
          btnName: 'Create New Role',
          btnIcon: 'ri-add-line',
          btnClick: onAddClick,
        }}
        ref={tableRef}
      />

      <FloatingCanvas
        id={'hs-test-123'}
        onClose={onClose}
        isVisible={isVisible}
        close={closeOffCanvas}
        title={offCanvasTitle}
        okText="Submit"
        closeText="Close"
      >
        {activeComponent === 'addRole' && (
          <AddRole permissions={permissions} data={data} isEdit={isEdit} onComplete={onComplete} />
        )}
      </FloatingCanvas>
    </>
  )
}
export default Roles
