export const MENUITEMS = [
  {
    menutitle: 'Menu',
  },
  
  {
    title: 'User Management',
    icon: <i className="bx bx-user side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    children: [
      {
        path: `${import.meta.env.BASE_URL}users`,
        title: 'Users',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'accountsPulse',
      },
      {
        path: `${import.meta.env.BASE_URL}roles`,
        title: 'Roles',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'accountsPulse',
      },
    ],
  },
  {
    title: 'App Orders',
    icon: <i className="bx bx-building side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    children: [
      {
        path: `${import.meta.env.BASE_URL}order-management`,
        title: 'Order Management',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'appOrdersPulse.order',
      },
   
    ],
  },
  {
    title: 'Availability',
    icon: <i className="bi bi-cart-check-fill side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    children: [
      {
        path: `${import.meta.env.BASE_URL}store`,
        title: 'Stores',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'availability.store',
      },
      {
        path: `${import.meta.env.BASE_URL}modifiers`,
        title: 'Modifiers',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'availability.modifier',
      },
      {
        path: `${import.meta.env.BASE_URL}Menu`,
        title: 'Menu',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'availability.menu',
      },
    ],
  },
]
