import { gql } from '@apollo/client'

export const GET_ALL_STORES = gql`
  query storeListQuery($search: String, $limit: Int, $offset: Int) {
    storeListQuery(search: $search, limit: $limit, offset: $offset) {
      limit
      offset
      totalPages
      rows {
        id
        company{
          id
          name {
            ar
            en
          }
        }
        name {
          ar
          en
        }
        status
        open
        close
        address {
          street {
            ar
            en
          }
          city {
            ar
            en
          }
          address {
            ar
            en
          }
          nationality {
            ar
            en
          }
        }
        storeContacts {
          email
          phone
          phoneCode
        }
        outletCode
        timeZone
        currency
        services
        paymentTypes
        countryCode
        photo
        config {
          deliveryConfig {
            costPerKm
            minCartValue
            minDeliveryCost
            minDeliveryForKm
            maxDeliveryDistance
          }
        }
        location {
          type
          coordinates
        }
        isClosed
        createdAt
      }
    }
  }
`

export const GET_COMPANY_STORES = gql`
  query storeListQuery($companyId: Int, $search: String, $limit: Int, $offset: Int) {
    storeListQuery(filters: { companyId: $companyId }, search: $search, limit: $limit, offset: $offset) {
      limit
      offset
      totalPages
      rows {
        createdAt
        companyId
        id
        name {
          ar
          en
        }
        isClosed
      }
    }
  }
`

export const GET_MODIFIER_GROUP = gql`
  query modifierGroupQuery($companyId: Int, $limit: Int, $search: String) {
    modifierGroupQuery(companyId: $companyId, limit: $limit, search: $search) {
      rows {
        id
        modGroupName {
          ar
          en
        }
        modGroupType
        status
      }
    }
  }
`
