import React from 'react'
import logo1 from '@hub/assets/images/brand-logos/desktop-logo.png'

const Crm = () => {
  // for User search function

  return (
    <div className="md:flex block items-center justify-center my-[1.5rem] page-header-breadcrumb flex h-[calc(100vh-150px)]">
      <div className="m-auto">
        <img src={logo1} alt="logo" className="h-[50px] w-auto mb-10 mx-auto" />

        <p className="font-semibold text-[2.5rem] text-center text-defaulttextcolor dark:text-defaulttextcolor/70 !mb-0 ">
          Welcome To Pulse Portal
        </p>
      </div>
    </div>
  )
}

export default Crm
