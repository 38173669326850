import { CLOUD_FUNCTIONS } from '../../constants/constants'
import { callCloudFunction } from './cloud'

export const createUser = async (data) => {
  return callCloudFunction(CLOUD_FUNCTIONS.CREATE_HUB_SUBADMINS, data)
}
export const updateRoles = async (data) => {
  return callCloudFunction(CLOUD_FUNCTIONS.UPDATE_HUB_ROLE, data)
}
export const translate = async (data) => {
  return callCloudFunction(CLOUD_FUNCTIONS.TRANSLATE, data)
}
