import {
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  SET_SELECTED_COMPANY,
  SET_SELECTED_STORE
} from './action'

const initialState = {
  user: null,
  permissions: [],
  companies: [],
  stores: [],
  selectedCompany: null,
  selectedStore: null,
  loading: false,
  error: null,
  menuItems: [],
}
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payload.userData,
        permissions: action.payload.permissions,
        companies: action.payload.companies,
        stores: action.payload.stores,
        menuItems: action.payload.menuItems,
        loading: false,
        error: null,
      }

    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case SET_SELECTED_COMPANY:
      localStorage.setItem('selectedCompany', JSON.stringify(action.company))
      return {
        ...state,
        selectedCompany: action.company,
      }
      case SET_SELECTED_STORE:
        localStorage.setItem('selectedStore', JSON.stringify(action.store))
        return {
          ...state,
          selectedStore: action.store,
        }

    default:
      return state
  }
}
