import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './TreeStyles.css'; // Custom styles

const Tree = ({ data, values = [], onChange }) => {
  const [expanded, setExpanded] = useState([]);
  const [checkedItems, setCheckedItems] = useState(values);

  const memoizedData = useMemo(() => data, [data]);

  const processTreeNodes = (nodes, checkedList) => {
    const recursivelyProcessNodes = (node) => {
      if (node.children) {
        const childValues = node.children.map((child) => child.value);

        // Recursively process children
        node.children.forEach(recursivelyProcessNodes);

        // Check if all children are checked
        const allChildrenChecked = childValues.every((childValue) =>
          checkedList.includes(childValue)
        );

        // Add parent if all children are checked, remove otherwise
        if (allChildrenChecked) {
          if (!checkedList.includes(node.value)) {
            checkedList.push(node.value);
          }
        } else {
          const parentIndex = checkedList.indexOf(node.value);
          if (parentIndex > -1) {
            checkedList.splice(parentIndex, 1);
          }
        }
      }
    };

    // Process all nodes
    nodes.forEach(recursivelyProcessNodes);
    return checkedList;
  };

  const handleCheck = (newChecked) => {
    const updatedChecked = processTreeNodes(memoizedData, [...newChecked]);
    setCheckedItems(updatedChecked);
    onChange?.(updatedChecked);
  };

  return (
    <>
      <CheckboxTree
        nodes={memoizedData}
        checked={checkedItems}
        expanded={expanded}
        onCheck={handleCheck}
        onExpand={(expanded) => setExpanded(expanded)}
        icons={{
          parentClose: null,
          parentOpen: null,
          leaf: null,
        }}
      />
    </>
  );
};

Tree.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      children: PropTypes.array,
    })
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func,
};

export default Tree;
