export function convertTime(time) {
  const hours = time.split(':')[0]
  const minutes = time.split(':')[1]
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const newHours = hours % 12 === 0 ? 12 : hours % 12

  return `${newHours}:${minutes} ${ampm}`
}
export const getInitials = (firstName = '', lastName = '') => {
  const firstInitial = firstName.slice(0, 1).toUpperCase()
  const lastInitial = lastName.slice(0, 1).toUpperCase()
  return `${firstInitial}${lastInitial}`.trim()
}


/**
 * Normalizes the value for the SelectBox component.
 * @param {string|array} value - The value(s) to normalize.
 * @returns {object|array|null} - The normalized value object(s) or null if not found.
 */
export const normalizeSelectBoxValue = (value) => {
    if (Array.isArray(value)) {
      return value.map(val => ({ value: val, label: val }));
    }
    if (typeof value === 'string') {
      return { value, label: value };
    }
    return null;
  };



export const timeStamptoDate = (time_stamp_date) => {
  return time_stamp_date ? new Date(time_stamp_date.seconds * 1000 + 19800000).toISOString().slice(0, 16) : '';
}
